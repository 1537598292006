<template>
    <!-- Adding New Department for Company and Its Modal Popup -->
    <div>
        <modal class="new-modal-default" :name="modal_name" transition="nice-modal-fade" :delay="100" :width="750"
            :height="650" :pivot-y="0.5" :adaptive="true" :scrollable="true">
            <div class="v-modal-content" style="background-color: #ffffff !important;">
                <div class="v-modal-header text-center">
                    <span class="v-modal-dialog-title v-modal-title">CHANGE PASSWORD</span>
                </div>
                <div class="v-modal-body">
                    <div class="v-modal-layout">
                        <div class="d-flex justify-content-center align-items-center flex-column mx-10">
                            <div class="my-10" style="color: #000000; font-weight: 500; font-size: 14px">
                                Change Password for <span style="color: #0d84f2">"{{ loggedin_id }}"</span>
                            </div>
                            <div class="d-flex mt-10 mb-20">
                                <div class="d-flex align-items-center">
                                    <span class="finishedCircle mx-5">
                                        1
                                        <span class="finishedCircleText">
                                            Current Password
                                        </span>
                                    </span>
                                    <span
                                        :class="toShow === 'otpVerification' || toShow === 'changePassword' ? 'finishedDash' : 'unfinishedDash'">
                                        
                                    </span>
                                    <span
                                        :class="toShow === 'otpVerification' || toShow === 'changePassword' ? 'finishedCircle' : 'unfinishedCircle'"
                                        class="mx-5">
                                        2
                                        <span
                                            :class="toShow === 'otpVerification' || toShow === 'changePassword' ? 'finishedCircleText' : 'unfinishedCircleText'">
                                            OTP Verification
                                        </span>
                                    </span>
                                    <span :class="toShow === 'changePassword' ? 'finishedDash' : 'unfinishedDash'">
                                        
                                    </span>
                                    <span :class="toShow === 'changePassword' ? 'finishedCircle' : 'unfinishedCircle'"
                                        class="mx-5">
                                        3
                                        <span
                                            :class="toShow === 'changePassword' ? 'finishedCircleText' : 'unfinishedCircleText'">
                                            Change Password
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div v-if="toShow === 'currentPassword'" style="width: 100%">
                                <div class="my-4" style="color: #393939; font-weight: 500; font-size: 12px">Current
                                    Password<sup class="fs-14 ml-1 mandatory">*</sup></div>
                                <div class="d-flex position-relative">
                                    <input v-if="!showPassword" style="padding-right: 40px !important;" autocomplete="new-password" type="password" oncopy="return false" 
                                        onpaste="return false" oncut="return false" class="digi-form-full-bordered"
                                        :class="errorMessage ? 'mb-0' : 'mb-5'" placeholder="Enter Password"
                                        v-model="currentPassword">
                                    <input v-else type="text" style="padding-right: 40px !important;" autocomplete="new-password" oncopy="return false"
                                        onpaste="return false" oncut="return false" class="digi-form-full-bordered"
                                        :class="errorMessage ? 'mb-0' : 'mb-5'" placeholder="Enter Password"
                                        v-model="currentPassword">
                                    <span @click="showPassword = !showPassword"
                                        style="position: absolute; margin-top: 8px; right: 10px; width: 20px; cursor: pointer;">
                                        <img v-if="!showPassword" src="/static/images/hide-password.svg" width="100%"
                                            alt="eye">
                                        <img v-else src="/static/images/show-password.svg" width="100%" alt="eye">
                                    </span>
                                </div>
                                <div class="mb-5" v-if="errorMessage"
                                    style="color: #e82828; font-weight: 500; font-size: 12px">{{ errorMessage }}</div>
                            </div>
                            <div v-if="toShow === 'otpVerification'" style="width: 100%">
                                <div v-if="loggedInUser.registration_type == 'email'" class="text-center mb-8" style="color: #3f3f3f; font-weight: 500; font-size: 14px">
                                    A 6 digit verification code has been sent to your
                                    <br>Email Address <span class="text-secondary">{{loggedInUser.login_id}}</span>
                                </div>
                                <div v-else class="text-center mb-8" style="color: #3f3f3f; font-weight: 500; font-size: 14px">
                                    A 6 digit verification code has been sent to your
                                    <br>Phone Number <span class="text-secondary">{{loggedInUser.login_id}}</span>
                                </div>
                                <!-- <div class="d-flex justify-content-between text-center mt-2 mb-10 mx-8">
                                    <div v-if="loggedInUser.verified.phone_number" :class="{'pointer-event':isMobile}">
                                        <div :class="send === 'phone' && !countdown == 0 ? 'greenSelection' : 'orangeSelection'"
                                            class="px-2 py-1" @click="sendMobile()">Send OTP to Mobile</div>
                                        <div v-if="primary_contact_number.length" class="idText my-2">
                                            xxxxxx{{ primary_contact_number[9]+primary_contact_number[10]+primary_contact_number[11]+primary_contact_number[12] || "" }}
                                        </div>
                                    </div>
                                    <div v-if="loggedInUser.verified.email_id" :class="{'pointer-event':isEmail}">
                                        <div :class="send === 'email' && !countdown == 0 ? 'greenSelection' : 'orangeSelection'"
                                            class="px-2 py-1" @click="sendEmail()">Send OTP to E-Mail ID</div>
                                        <div class="idText my-2">{{ primary_email || "" }}</div>
                                    </div>
                                </div> -->
                                <div class="d-flex align-items-start justify-content-center mb-1">
                                    <span class="enterOtpText mr-5" style="position: relative; top: 8px;">Enter OTP Code</span>
                                    <div class="d-flex align-items-start">
                                        <div>
                                            <div class="otp-div">
                                                <v-otp-input v-if="!otpInvalid" ref="otpInput" input-classes="otp-input"
                                                    separator="" :num-inputs="6" v-model="otp" :should-auto-focus="true"
                                                    :is-input-num="true" @on-change="otpEntered" @on-complete="otpEntered" v-validate="'required'"
                                                    name="otp" />
                                                <v-otp-input v-else ref="otpInput" input-classes="otp-input-warning"
                                                    separator="" :num-inputs="6" v-model="otp" :should-auto-focus="true"
                                                    :is-input-num="true" @on-change="otpEntered" @on-complete="otpEntered" v-validate="'required'"
                                                    name="otp" />
                                            </div>
                                            <div class="w-100">
                                                <span v-if="otpInvalid" style="color: #f61919;">Invalid OTP Code</span>
                                                <span style="color: #f5a623;font-size: 14px;" v-else>OTP Valid for 15
                                                    minutes</span>
                                                <span v-if="successMessage" style="color: #0475f3;">{{successMessage}}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="color: #3f3f3f">
                                                <button class="btn btn-new-primary pl-3 pr-3" type="button" :disabled="countdown > 0" @click="resendCode">Resend code</button> 
                                                <br> 
                                                <span v-if="!otpInvalid && !countdown == 0"> <span>in <span style="font-weight: 800;">{{countdown}}</span></span> seconds </span>
                                            </div>
                                            <div v-if="callInProgress" style="color: #0475f3; cursor: pointer;">Resending Code...</div>
                                            <!-- <span
                                                v-if="!otpInvalid && show_countdown && !errorMessage && !callInProgress && !countdown == 0"
                                                style="color: #3f3f3f">Resend code in <span
                                                    style="font-weight: 800;">{{countdown}}</span> seconds</span>
                                            <span v-if="otpInvalid" style="color: #f61919;">Invalid OTP Code</span>
                                            <span style="color: #f61919;" v-show="errors.has('otp')">OTP Code is
                                                Required</span>
                                            <span v-if="!otpInvalid && errorMessage" class="text-danger"
                                                style="font-size: 14px; font-weight: 600;">{{errorMessage}}</span>
                                            <span v-if="(countdown == 0 || otpInvalid) && !callInProgress"
                                                style="color: #0475f3; cursor: pointer;" @click="resendCode">Resend
                                                Code</span>
                                            <span v-if="callInProgress" style="color: #0475f3;">Resending Code...</span> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="d-flex align-items-center justify-content-center flex-column mb-1"
                                    style="margin-left: 8em; margin-right: 9em;">
                                    <div class="text-right mb-10 w-100">
                                        <span style="color: #f53123;font-size: 14px;" v-if="otpExpired">OTP
                                            Expired</span>
                                        <span style="color: #f5a623;font-size: 14px;" v-else>OTP Valid for 15
                                            minutes</span>
                                    </div>
                                    <span class="d-flex mb-8 w-100"
                                        :class="countdown == 0 && !otpInvalid || callInProgress ? 'justify-content-end' : 'justify-content-between'">
                                        <span
                                            v-if="!otpInvalid && show_countdown && !errorMessage && !callInProgress && !countdown == 0"
                                            style="color: #3f3f3f">Resend code in <span
                                                style="font-weight: 800;">{{countdown}}</span> seconds</span>
                                        <span v-if="otpInvalid" style="color: #f61919;">Invalid OTP Code</span>
                                        <span style="color: #f61919;" v-show="errors.has('otp')">OTP Code is
                                            Required</span>
                                        <span v-if="!otpInvalid && errorMessage" class="text-danger"
                                            style="font-size: 14px; font-weight: 600;">{{errorMessage}}</span>
                                        <span v-if="(countdown == 0 || otpInvalid) && !callInProgress"
                                            style="color: #0475f3; cursor: pointer;" @click="resendCode">Resend
                                            Code</span>
                                        <span v-if="callInProgress" style="color: #0475f3;">Resending Code...</span>
                                        <span v-if="successMessage" style="color: #0475f3;">{{successMessage}}</span>
                                    </span>
                                </div> -->
                            </div>
                            <div v-if="toShow === 'changePassword'" style="width: 100%">
                                <div class="my-4" style="color: #393939; font-weight: 500; font-size: 12px">New
                                    Password<sup class="fs-14 ml-1 mandatory">*</sup></div>
                                <div class="d-flex position-relative">
                                    <input v-if="!showNewPassword" type="password" oncopy="return false"
                                        onpaste="return false" style="padding-right: 40px !important;" oncut="return false" @input="onPassInput"
                                        class="digi-form-full-bordered mb-5" name="password"
                                        placeholder="Enter Password" v-model="newPassword"
                                        v-validate="{required:true,min:8,regex:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=()!*]).*$/}"
                                        autocomplete="new-password">
                                    <input v-else type="text" oncopy="return false" onpaste="return false"
                                        oncut="return false" style="padding-right: 40px !important;" class="digi-form-full-bordered mb-5" @input="onPassInput"
                                        name="password" placeholder="Enter Password" v-model="newPassword"
                                        v-validate="{required:true,min:8,regex:/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=()!*]).*$/}"
                                        autocomplete="new-password">
                                    <span @click="showNewPassword = !showNewPassword"
                                        style="position: absolute; margin-top: 8px; right: 35px; width: 20px; cursor: pointer;">
                                        <img v-if="!showNewPassword" src="/static/images/hide-password.svg" width="100%"
                                            alt="eye">
                                        <img v-else src="/static/images/show-password.svg" width="100%" alt="eye">
                                    </span>
                                    <el-tooltip effect="light" placement="left">
                                        <div slot="content" style="color: #3f3f3f; font-size: 12px;">
                                            <span style="font-weight: 600;">Password must contain the following</span>
                                            <br>
                                            <span style="width:200px">
                                                <span>
                                                    <i v-if="is_uppercase_pwd"
                                                        class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                    <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                    At-least one uppercase letter (ex: A to Z)
                                                </span><br>
                                                <span>
                                                    <i v-if="is_number_pwd"
                                                        class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                    <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                    At-least one digit (ex: 0 to 9)
                                                </span><br>
                                                <span>
                                                    <i v-if="is_special_character_pwd"
                                                        class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                    <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                    At-least one special character (ex: $,#,@,!,%,^,&,*,(,),)<br>
                                                </span>
                                                <span>
                                                    <i v-if="min_pwd_length"
                                                        class="icon icon-check-circle text-success fs-14 mr-1"></i>
                                                    <i v-else class="icon icon-close-circle text-danger fs-14 mr-1"></i>
                                                    Minimum 8 characters
                                                </span><br>
                                            </span>
                                        </div>
                                        <i class="icon-information text-secondary"
                                            style="font-size: 25px; margin: 5px 0px 0px 5px"></i>
                                    </el-tooltip>
                                </div>
                                <span class="invalid-feedback-form text-danger mb-5 mt-0"
                                    v-show="errors.has('password')">{{ errors.first("password") }}</span>
                                <div class="my-4" :style="repeatPasswordIncorrect ? 'color: #e82828' : 'color: #393939'"
                                    style="font-weight: 500; font-size: 12px">Repeat Password<sup
                                        class="fs-14 ml-1 mandatory">*</sup></div>
                                <div class="d-flex position-relative">
                                    <input v-if="!showRepeatPassword" autocomplete="off" oncopy="return false"
                                        onpaste="return false" oncut="return false"
                                        :style="repeatPasswordIncorrect ? 'color: #e82828' : 'color: #393939'"
                                        name="repeat_password" type="password" class="digi-form-full-bordered mb-5"
                                        style="margin-right: 28px; padding-right: 40px !important;" placeholder="Enter Password"
                                        v-model="repeatPassword">
                                    <input v-else autocomplete="off" oncopy="return false" onpaste="return false"
                                        oncut="return false"
                                        :style="repeatPasswordIncorrect ? 'color: #e82828' : 'color: #393939'"
                                        name="repeat_password" type="text" class="digi-form-full-bordered mb-5"
                                        style="margin-right: 28px; padding-right: 40px !important;" placeholder="Enter Password"
                                        v-model="repeatPassword">
                                    <span @click="showRepeatPassword = !showRepeatPassword"
                                        style="position: absolute; margin-top: 8px; right: 35px; width: 20px; cursor: pointer;">
                                        <img v-if="!showRepeatPassword" src="/static/images/hide-password.svg"
                                            width="100%" alt="eye">
                                        <img v-else src="/static/images/show-password.svg" width="100%" alt="eye">
                                    </span>
                                </div>
                                <div class="mb-4" v-if="repeatPasswordIncorrect"
                                    style="color: #e82828; font-weight: 500; font-size: 10px">Passwords does not match
                                </div>
                            </div>
                            <div class="d-flex justify-content-end align-items-center mt-6"
                                :class="toShow === 'currentPassword' ? 'mb-20' : 'mb-10'" style="width: 100%">
                                <button class="btn btn-new-primary-bordered btn-smm mr-3" style="border-radius: 8px !important;"
                                    @click="hideChangePassword">CANCEL</button>
                                <button v-if="toShow != 'changePassword'" class="btn btn-new-primary btn-smm ml-3" style="border-radius: 8px !important;"
                                    @click="next">Next</button>
                                <button v-else class="btn btn-new-primary btn-smm ml-3" style="border-radius: 8px !important;" @click="next">Change
                                    Password</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
import globals from '../globals';
    export default {
        mixins: [],
        data() {
            return {
                toShow: "currentPassword",
                loggedin_id: "",
                showPassword: false,
                showNewPassword: false,
                showRepeatPassword: false,
                password: "",
                otpInvalid: false,
                send: "",
                primary_contact_number: "",
                primary_email: "",
                currentPassword: "",
                repeatPassword: "",
                newPassword: "",
                verifyToken: "",
                otp: "",
                errorMessage: "",
                successMessage: "",
                repeatPasswordIncorrect: false,
                countdown: 60,
                callInProgress: false,
                otpExpired: false,
                show_countdown: false,
                min_pwd_length: false,
                is_uppercase_pwd: false,
                is_number_pwd: false,
                is_special_character_pwd: false,
                otpWarning: '',
                isEmail: false,
                isMobile: false,
                verifyTokenNew: "",
            }
        },
        props: ["modal_name"],
        methods: {
            countDownTimer() {
                this.show_countdown = true
                if (this.countdown > 0 && this.toShow === "otpVerification" && !this.otpVerifying) {
                    setTimeout(() => {
                        this.countdown -= 1;
                        this.countDownTimer();
                    }, 1000);
                } else if (this.countdown === 0 && !this.otpVerifying) {
                    this.clearOtpInputs();
                    this.show_countdown = false
                    this.isEmail = false
                    this.isMobile = false
                }
            },
            clearOtpInputs() {
                this.$refs.otpInput.clearInput();
                this.otp = "";
            },
            hideChangePassword() {
                this.toShow = "currentPassword";
                this.showPassword = false;
                this.showNewPassword = false;
                this.showRepeatPassword = false;
                this.password = "";
                this.otpInvalid = false;
                this.send = "";
                this.currentPassword = "";
                this.repeatPassword = "";
                this.newPassword = "";
                this.verifyToken = "";
                this.otp = "";
                this.errorMessage = "";
                this.successMessage = "";
                this.repeatPasswordIncorrect = false;
                this.$modal.hide(this.modal_name);
                this.countdown = 60;
                this.callInProgress = false;
                this.otpExpired = false;
                this.show_countdown = false;
                this.isEmail = false
                this.isMobile = false
                this.verifyTokenNew = "";
            },
            showSuccess(msg) {
                this.successMessage = msg;
                setTimeout(() => {
                    this.successMessage = '';
                }, 2000);
            },
            otpEntered(value) {
                this.otpInvalid = false;
                this.otp = value;
                this.$validator.reset();
            },
            resendCode() {
                this.callInProgress = true
                this.isMobile = true;
                this.isEmail = true;
                if (this.loggedInUser.registration_type == 'email' || this.loggedInUser.registration_type == 'phone') {
                    this.$http.post(globals.AUTH_SERVICE + "/auth/otp", {
                        login_id: this.loggedInUser.login_id,
                        otp_for: "change_pwd"
                    }).then(response => {
                        if (response.data.status_id == 1) {
                            this.verifyToken = response.data.otp_authorization;
                            this.showSuccess(response.data.response);
                            this.countdown = 60;
                            this.countDownTimer();
                            this.callInProgress = false
                        }
                    });
                }
            },
            next() {
                this.$validator.validateAll().then(result => {
                    if (result) {
                        if (this.toShow === 'currentPassword' && this.currentPassword) {
                            this.$http.post(globals.AUTH_SERVICE + "/auth/password/change", {
                                current_password: this.currentPassword,
                            }).then(response => {
                                if (response.data.status_id == 1) {
                                    this.toShow = "otpVerification";
                                    this.countdown = 60;
                                    this.countDownTimer();
                                } else {
                                    this.errorMessage = "Current Password is Incorrect";
                                }
                            }).catch(error => {
                                if (error.response.status == 401 || error.response.status == 400) {
                                    this.errorMessage = "Current Password is Incorrect";
                                }
                            });
                        } else if (this.toShow === 'currentPassword' && !this.currentPassword) {
                            this.errorMessage = "Please Enter Current Password";
                        }
                        //  /password/profile/change/verify
                        if (this.toShow === 'otpVerification' && this.otp) {
                            if ((this.loggedInUser.registration_type == 'email' || this.loggedInUser.registration_type == 'phone')) {
                                this.$http.post(globals.AUTH_SERVICE + "/auth/password/change", {
                                    otp: this.otp
                                }).then(response => {
                                    if (response.data.status_id == 1) {
                                        this.toShow = "changePassword";
                                        // this.verifyTokenNew = response.data.token;
                                    }
                                    // else {
                                    //     this.otpInvalid = true;
                                    // }
                                }).catch(err => {
                                    if(err.response.status == 400 || err.response.status == 401) {
                                        this.otpInvalid = true;
                                    }
                                });
                            }
                        }
                        if (this.toShow === 'changePassword' && this.newPassword && this.newPassword == this
                            .repeatPassword && this.repeatPassword && !this.errorMessage) {
                            this.$http.post(globals.AUTH_SERVICE + "/auth/password/change", {
                                new_password: this.newPassword,
                            }).then(response => {
                                if (response.data.status_id == 1) {
                                    this.hideChangePassword();
                                    this.$emit("passwordChanged");
                                }
                                // else {
                                //     this.$swal({
                                //         title: "Error",
                                //         html: res.data.reason,
                                //         type: "error",
                                //         confirmButtonText: "OK",
                                //         confirmButtonColor: "#0475f3",
                                //     });
                                // }
                            }).catch(err => {
                                this.$swal({
                                    title: "Error",
                                    html: err.data.reason,
                                    type: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: "#0475f3",
                                });
                            });
                        } else if (this.newPassword != this.repeatPassword) {
                            this.repeatPasswordIncorrect = true;
                        }
                    } else {
                        if(this.toShow === 'otpVerification' && this.otp.length < 6) {
                            this.otpInvalid = true;
                        };
                    }
                });
            },
            onPassInput(e) {
                if (e.target.value.match(/^(?=.{8,}$)/)) {
                    this.min_pwd_length = true;
                } else {
                    this.min_pwd_length = false;
                }
                if (e.target.value.match(/^(?=.*[A-Z])/)) {
                    this.is_uppercase_pwd = true;
                } else {
                    this.is_uppercase_pwd = false;
                }
                if (e.target.value.match(/^(?=.*[0-9])/)) {
                    this.is_number_pwd = true;
                } else {
                    this.is_number_pwd = false;
                }
                if (e.target.value.match(/^(?=.*[!@#$%^&*()])/)) {
                    this.is_special_character_pwd = true;
                } else {
                    this.is_special_character_pwd = false;
                }
            },
        },
        async created() {
            if (this.loggedInUser.registration_type == "email") {
                this.loggedin_id = this.loggedInUser.login_id;
            } else {
                let phn = this.loggedInUser.login_id.split("");
                this.loggedin_id = "XXXXXX" + phn[6] + phn[7] + phn[8] + phn[9];
            }
            let res = await this.$http.post("/users/get_user_data", {
                user_id: this.$route.params.id
            });
            if (res.data.status_id == 1) {
                this.primary_contact_number = res.data.response.primary_phone ? res.data.response.primary_phone
                    .value.phone_number : "";
                this.primary_email = res.data.response.primary_email ? res.data.response.primary_email.value : '';
            }
        },
        mounted() {
            $('form[autocomplete="off"] input, input[autocomplete="off"]').each(function(){

                var input = this;
                var name = $(input).attr('name');
                var id = $(input).attr('id');

                $(input).removeAttr('name');
                $(input).removeAttr('id');      

                setTimeout(function(){ 
                    $(input).attr('name', name);
                    $(input).attr('id', id);            
                }, 1);
            });
        },
        watch: {
            currentPassword: function (newVal, oldVal) {
                if (newVal) {
                    this.errorMessage = "";
                }
            },
            repeatPassword: function (newVal, oldVal) {
                if (newVal) {
                    this.repeatPasswordIncorrect = false;
                }
            },
            toShow: function (newVal, oldVal) {
                if (newVal == "changePassword") {
                    this.newPassword = "";
                    this.repeatPassword = "";
                }
                // if (newVal == "otpVerification") {
                //     this.countDownTimer();
                // } else {
                //     this.countdown = 60;
                // }
            }
        },
        components: {
            Swal
        },
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        },
    }
</script>

<style scoped>
    .unfinishedCircle {
        background: #e8e8e8;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.32);
        font-weight: 500;
    }

    .finishedCircle {
        background: #128807;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        min-width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .unfinishedDash {
        color: #e8e8e8;
        border-top: 2px dashed #e8e8e8;
        min-width: 100px;
        font-size: 20px;
    }

    .finishedDash {
        color: #128807;
        min-width: 100px;
        border-top: 2px dashed #128807;
        font-size: 20px;
    }

    .finishedCircleText {
        position: absolute;
        font-size: 14px;
        color: #000000;
        margin-top: 5em;
    }

    .unfinishedCircleText {
        position: absolute;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.32);
        margin-top: 5em;
        width: 10em;
    }

    .orangeSelection {
        background: #f5a623;
        border-radius: 20px;
        color: #3f3f3f;
        font-weight: 600;
        font-size: 12px;
        width: 16em;
        cursor: pointer;
    }

    .greenSelection {
        background: #128807;
        border-radius: 20px;
        color: #ffffff;
        font-weight: 600;
        font-size: 12px;
        width: 16em;
        cursor: pointer;
    }

    .idText {
        color: rgba(0, 0, 0, 0.41);
        font-weight: 500;
    }

    .enterOtpText {
        color: #3f3f3f;
        font-size: 14px;
        font-weight: 500;
    }

    .pointer-event {
        pointer-events: none;
        opacity: 0.6;
    }
</style>